import { fetchAsyncAll } from "actions/AsyncAction";
import SuggestField from "components/fields/SuggestField";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Api from "utilities/api";
import formatter from "utilities/formatter";
import { snakecaseKeys } from "utilities/Utils";

/**
 * 経費科目に関して操作する際に利用するコンポーネント
 */
export default class CategorySuggestField extends PureComponent {
  constructor(props) {
    // バグで @deprecated が出てきているだけで実際は非推奨でないため使用
    // @see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/47784
    super(props);

    this.state = {
      selectedCategory: {
        id: null,
        name: null,
      },
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.fetchOptions = this.fetchOptions.bind(this);
  }

  componentDidMount() {
    if (this.props.initCategoryId !== void 0) {
      Api.rootCategories
        .show({ id: this.props.initCategoryId })
        .then((response) => {
          this.setState({
            selectedCategory: { id: response.id, name: response.name },
          });
        })
        .catch(() => void 0);
    }
  }

  fetchOptions(searchText) {
    const requestParams = {
      enable: true,
    };

    if (!_.isNil(searchText) && !_.isEmpty(searchText)) {
      _.set(requestParams, "name", searchText);
    }

    return fetchAsyncAll(
      (params) =>
        Api.rootCategories
          .index(
            snakecaseKeys({
              ...requestParams,
              ...params,
            }),
          )
          .then((result) => {
            return { data: result.categories, count: result.count };
          }),
      {
        /* APIを投げる際の追加パラメータは特になし */
      },
      { limit: 200 },
    ).then(({ data }) => {
      // showParentCategoryがfalseの時はサジェストには親科目を除いた科目を表示
      const categories = data
        .filter(
          (category) => this.props.showParentCategory || category.selectable,
        )
        .map((category) => ({
          id: category.id,
          name: category.name,
        }));
      return { count: categories.length, data: categories };
    });
  }

  getOptionText(option) {
    return option.name;
  }

  renderOption(option) {
    return formatter.text(option && option.name);
  }

  onTextChange(text) {
    this.setState({
      selectedCategory: { ...this.state.selectedCategory, name: text },
    });
    if (this.props.onTextChange !== void 0) {
      this.props.onTextChange(text);
    }
  }

  onSelect(category) {
    this.setState({
      selectedCategory: { id: category?.id, name: category?.name },
    });
    this.props.onSelect(category);
  }

  render() {
    return (
      <SuggestField
        async
        text={this.props.text || this.state.selectedCategory.name}
        value={this.props.value || this.state.selectedCategory}
        fetchOptions={this.fetchOptions}
        getOptionText={this.getOptionText}
        renderOption={this.renderOption}
        onSelect={this.props.onSelect}
        onTextChange={this.onTextChange}
      />
    );
  }
}

CategorySuggestField.defaultProps = {
  showParentCategory: true,
};

CategorySuggestField.propTypes = {
  /** 最初から選択しておきたい経費科目のID */
  initCategoryId: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
  showParentCategory: PropTypes.bool,
};
