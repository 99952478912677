import ActionTriggerByScroll from "components/ActionTriggerByScroll";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import AutoSuggest from "react-autosuggest";
import { from, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
import { handleSuggestionSelected } from "utilities/searchKeyHandler";
import { getIncrementalMatcher } from "utilities/Utils";
import Clearable from "./Clearable";

/**
 * サジェスト付きの入力フィールド
 *
 * 機能は、以下の通り
 *   1. スクロールすると、順次必要な分のサジェストを取得する
 *   2. 入力に応じて、サジェスト取得処理を制御
 *     - IME使用時は、入力確定時にサジェストを取得
 *     - サジェスト取得処理の間隔を、一定時間空ける
 */
export default class SuggestField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      id: _.uniqueId("suggest-field-"),
      lastSearchText: null, // 最後に検索を実行した時の、検索文字列
      isComposing: false,
      composingText: "",
      options: props.initialOptions || [],
      totalCount: null,
      waitingPromise: null,
    };

    this.shouldRenderOptions = this.shouldRenderOptions.bind(this);
    this.renderInput = this.renderInput.bind(this);
    this.renderSuggestionsContainer =
      this.renderSuggestionsContainer.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleComposition = this.handleComposition.bind(this);
    this.handleClearButtonClick = this.handleClearButtonClick.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.handleOptionsFetchRequest = this.handleOptionsFetchRequest.bind(this);
    this.handleOptionsClearRequest = this.handleOptionsClearRequest.bind(this);
    this.fetchOptions = this.fetchOptions.bind(this);
    this.fetchNextOptions = this.fetchNextOptions.bind(this);
  }

  componentDidMount() {
    this.initSearchSubscription();
    this.initScrollSubscription();
  }

  componentWillUnmount() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }

    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
  }

  get searchEvent$() {
    if (!this._searchEvent$) {
      this._searchEvent$ = new Subject().pipe(
        debounceTime(300),
        switchMap((text) => from(this.fetchOptions(text, 0, this.batchSize))), // 最新の検索結果を使う
      );
    }

    return this._searchEvent$;
  }

  get scrollEvent$() {
    if (!this._scrollEvent$) {
      this._scrollEvent$ = new Subject().pipe(
        distinctUntilChanged(
          (x, y) => x.text === y.text && x.offset === y.offset,
        ),
        filter((result) => result.text === this.props.text), // スクロール時から検索条件が変わっている場合、無視する
      );
    }

    return this._scrollEvent$;
  }

  get id() {
    return this.state.id;
  }

  get isAllFetched() {
    if (_.isNil(this.state.totalCount)) {
      return false;
    }

    return this.state.totalCount <= this.state.options.length;
  }

  get isOptionsLocked() {
    return this.state.isComposing;
  }

  get batchSize() {
    return this.props.batchSize || 20;
  }

  get inputValue() {
    return (
      (this.state.isComposing ? this.state.composingText : this.props.text) ||
      ""
    );
  }

  get inputProps() {
    const props = {
      className: this.props.className || "form-control",
      type: this.props.type,
      value: this.inputValue,
      placeholder: this.props.placeholder,
      onChange: this.handleTextChange,
      onCompositionStart: this.handleComposition,
      onCompositionUpdate: this.handleComposition,
      onCompositionEnd: this.handleComposition,
      disabled: this.props.disabled,
    };

    if (typeof this.props.getInputProps === "function") {
      return this.props.getInputProps(props);
    }

    return props;
  }

  initSearchSubscription() {
    if (this.searchSubscription) {
      // 実行されない
      this.searchSubscription.unsubscribe();
    }

    this.searchSubscription = this.searchEvent$.subscribe(
      ({ options, totalCount }) => {
        this.resetOptions(options, totalCount);
      },
    );
  }

  initScrollSubscription() {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }

    this.scrollSubscription = this.scrollEvent$.subscribe(
      ({ options, totalCount }) => {
        this.appendOptions(options, totalCount);
      },
    );
  }

  /**
   * input関連のイベントハンドラ
   */
  handleTextChange(e, { newValue, method }) {
    this.clearValue();

    if (this.state.isComposing) {
      this.setState({ composingText: newValue });
    } else {
      this.props.onTextChange(newValue);
    }
  }

  /**
   * IME使用中のイベントを処理
   * 確定前の段階では、内部のstateのみを変更し、外部向けのcallbackをcallしない
   * （propsが適切に更新されない場合、入力文字列が逐次確定される可能性があるため）
   */
  handleComposition(e) {
    switch (e.type) {
      case "compositionstart": {
        if (!this.state.isComposing) {
          this.setState({ isComposing: true, composingText: this.props.text });
        }
        break;
      }
      case "compositionupdate": {
        if (!this.state.isComposing) {
          this.setState({ isComposing: true });
        }
        break;
      }
      case "compositionend": {
        // compositionendの後、changeイベントは呼ばれない
        this.props.onTextChange(this.state.composingText);
        this.setState({ isComposing: false, composingText: "" });

        // setStateが非同期処理なので、ここで補完候補の更新命令を発行しないと、
        // 入力完了時に補完候補が更新されなくなる
        this.requestReset(e.target.value);
        break;
      }
      default:
        break;
    }
  }

  handleClearButtonClick(e) {
    this.clearValue();
    this.props.onTextChange("");
    this.requestReset("");
  }

  /**
   * AutoSuggest関連のイベントハンドラ
   */
  handleOptionSelect(e, { suggestion, suggestionValue, method }) {
    handleSuggestionSelected(method);
    this.props.onSelect(suggestion);
  }

  handleOptionsFetchRequest({ value, reason }) {
    if (!this.isOptionsLocked) {
      this.requestReset(value);
    }
  }

  handleOptionsClearRequest() {
    this.setState({ options: [] });
  }

  clearValue() {
    if (_.isNil(this.props.value)) {
      return;
    }

    this.props.onSelect(null);
  }

  /**
   * 補完候補の取得処理。
   * ローカルでの検索と、サーバー問い合わせの処理の違いを隠す
   */
  async fetchOptions(text, offset, limit) {
    if (this.props.async) {
      try {
        const { data, count } = await this.props.fetchOptions(
          text,
          offset,
          limit,
        );
        return { text, options: data, totalCount: count };
      } catch (e) {
        // 通信エラー等は無視
        return { text, options: [], totalCount: this.state.totalCount };
      }
    }

    const options = this.filterOptions(text);
    return { text, options, totalCount: options.length };
  }

  /**
   * async = trueの時、scrollによって次の補完候補を取得する
   * ActionTriggerByScrollの仕様に合わせて、ObservableではなくPromiseを使う
   *
   * NOTE:
   *   矢印キーを使った時は、トリガーが画面上に表示されないため、発火しない
   *   上矢印押下時の挙動を、WAI-ARIAに合わせる（補完候補非表示の時に、上矢印を押すと、最後の補完候補にfocusが当たる）
   *   ためには、止むを得ないと思われる
   */
  async fetchNextOptions() {
    if (!this.props.async) {
      return;
    }

    const offset = this.state.options.length;

    const { text, options, totalCount } = await this.fetchOptions(
      this.props.text,
      offset,
      this.batchSize,
    );
    this.scrollEvent$.next({
      offset,
      text,
      options,
      totalCount,
    });
  }

  resetOptions(options, totalCount) {
    this.setState({ options, totalCount });
    this.initScrollSubscription();
  }

  appendOptions(options, totalCount) {
    const newOptions = [...this.state.options, ...options];
    this.setState({ options: newOptions, totalCount });
  }

  /** async = falseの時、ローカルにある補完候補から、候補を絞り込む */
  filterOptions(value) {
    if (this.props.filterOptions) {
      return this.props.initialOptions.filter(this.props.filterOptions);
    }

    const separatorLength = 500;
    const reg = getIncrementalMatcher(value);

    return this.props.initialOptions.filter((x) => {
      const suggestionValue = this.props.getOptionText(x);
      return value.length <= separatorLength
        ? suggestionValue.match(reg)
        : suggestionValue.includes(value);
    });
  }

  requestReset(text) {
    this.searchEvent$.next(text);
  }

  shouldRenderOptions(_value) {
    // TODO: optionsが空の時、表示されないようにする。
    // ただし、新規にFocusが当たる時は、trueを返さないと、optionsが取得されないことに注意。
    return true;
  }

  renderStaticInput() {
    return (
      <p className="form-control-static">
        {this.props.renderOption(this.props.value)}
      </p>
    );
  }

  renderInput(inputProps) {
    return (
      <Clearable
        onClear={this.handleClearButtonClick}
        disabled={this.props.disabled}
      >
        <input {...inputProps} />
      </Clearable>
    );
  }

  renderSuggestionsContainer({ containerProps, children }) {
    // NOTE: childrenがnullの時、補完候補のリストが開いていない
    // TODO: InfiniteScrollerを使う

    return (
      <ActionTriggerByScroll
        containerProps={containerProps}
        isFinished={_.isNil(children) || this.isAllFetched}
        onLoad={this.fetchNextOptions}
        showStatus={false}
      >
        {children}
      </ActionTriggerByScroll>
    );
  }

  render() {
    if (this.props.isStatic) {
      return this.renderStaticInput();
    }

    return (
      <AutoSuggest
        id={this.id}
        theme={SuggestField.theme}
        shouldRenderSuggestions={this.shouldRenderOptions}
        suggestions={this.state.options}
        getSuggestionValue={(v) => {
          if (v !== undefined) return this.props.getOptionText(v);
          return ""; // react-autosuggestのバグで、サジェスト中に上下キー押下するとundefinedが入ってくる 2022/03/10現在、react-autosuggestの最新でも直っていない
        }}
        onSuggestionsFetchRequested={this.handleOptionsFetchRequest}
        onSuggestionsClearRequested={this.handleOptionsClearRequest}
        inputProps={this.inputProps}
        onSuggestionSelected={this.handleOptionSelect}
        renderInputComponent={this.renderInput}
        renderSuggestion={this.props.renderOption}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
      />
    );
  }
}

// TODO: ESLintを5系に上げた後、 static theme = {} のように書き換え
SuggestField.theme = {
  container: "react-autosuggest-container",
  containerOpen: "react-autosuggest-container-open",
  input: "react-autosuggest-input",
  inputOpen: "react-autosuggest-input-open",
  inputFocused: "react-autosuggest-input-focused",
  suggestionsContainer: "react-autosuggest-suggestions-container",
  suggestionsContainerOpen: "react-autosuggest-suggestions-container-open",
  suggestionsList: "react-autosuggest-suggestions-list",
  suggestion: "react-autosuggest-suggestion",
  suggestionFirst: "react-autosuggest-suggestion-first",
  suggestionHighlighted: "react-autosuggest-suggestion-highlighted",
  sectionContainer: "react-autosuggest-section-container",
  sectionContainerFirst: "react-autosuggest-section-container-first",
  sectionTitle: "react-autosuggest-section-title",
};

SuggestField.defaultProps = {
  isStatic: false,
  async: false,
  text: "",
  type: "search",
  disabled: false,
  /**
   * @param {string} _text 現在の入力値。空文字のこともある
   * @param {number} _offset
   * @param {number} _limit
   * @return {Promise} 必ず、 { data: [], count: 0 } のように、dataとcountをkeyに持つオブジェクトを返すこと
   */
  fetchOptions(_text, _offset, _limit) {
    window.console.warn("fetchOptions is not implemented: SuggestField");
  },
  onTextChange(_text) {
    window.console.warn("onTextChange is not implemented: SuggestField");
  },
  renderOption(option) {
    return option;
  },
};

SuggestField.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  // falseの時、ローカルで検索を実行する。描画後のフラグ変更は不可
  async: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
  // 初回描画時に、補完候補を渡す。async = falseの時は、以降、この補完候補から絞り込みが行われる
  initialOptions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string.isRequired }),
  ),
  getOptionText: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onTextChange: PropTypes.func, // 現在のinputの文字列を伝えるcallback。isStatic = trueの時は必須

  /** async = true の時のオプション */
  batchSize: PropTypes.number, // async = trueの時、一回あたりの取得件数を指定する
  fetchOptions: PropTypes.func,

  /** async = false の時のオプション */
  filterOptions: PropTypes.func, // デフォルトの検索処理を上書きする

  /** inputタグ用 */
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  getInputProps: PropTypes.func,
  disabled: PropTypes.bool,
};
