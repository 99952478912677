import { AsInvoiceChecksCheckBoxGroupField } from "applications/payment_requests/components/reports/fields/AsInvoiceChecksCheckBoxGroupField";
import { ReportLabelsSearchField } from "applications/payment_requests/components/reports/fields/ReportLabelsSearchField";
import { RequestSearchSpecification } from "applications/requests/specifications/RequestSearchSpecification";
import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import CheckBoxGroup from "components/CheckBoxGroup";
import DepartmentSelector from "components/DepartmentSelector";
import CategorySuggestField from "components/fields/CategorySuggestField";
import LabeledComponent from "components/LabeledComponent";
import PeriodInput from "components/PeriodInput";
import SmartCheckBox from "components/SmartCheckBox";
import i18next from "i18n";
import _isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import formatter from "utilities/formatter";
import { handleKeyPress } from "utilities/searchKeyHandler";
import { AppropriatedAtRangeField as InvoiceAppropriatedAtRangeField } from "./ConditionFields/Invoice/AppropriatedAtRangeField";
import { DueAtRangeField as InvoiceDueAtRangeField } from "./ConditionFields/Invoice/DueAtRangeField";
import { ScheduleAtRangeField as InvoiceScheduleAtRangeField } from "./ConditionFields/Invoice/ScheduleAtRangeField";
import PaperlessStatusCheckBoxes from "./PaperlessStatusCheckBoxes";
import RequestStatusCheckBoxes from "./RequestStatusCheckBoxes";

const renderApprover = (approver) => formatter.memberPull(approver);

const renderApprovalFlow = (approvalFlow) => (
  <span className="txt">{approvalFlow.name}</span>
);

const renderText = (text) => <span className="txt">{text}</span>;

const SearchButton = styled.button`
  width: 100%;
`;

export default class RequestSearchBox extends Component {
  async componentDidMount() {
    await this.props.onInitSearchConditions();
    // ページを開いた時にデータ取得をする
    this.search();
  }

  search() {
    this.props.search();
    this.props.saveSearchConditions();
  }

  async reset() {
    await this.props.onResetSearchConditions();
    this.search();
  }

  handleInput(callback, e) {
    callback(e.target.value);
  }

  render() {
    const isLoading = this.props.searchCondition.isLoading;
    const specification = this.props.specification;
    const { genericFields } = userPreferences.preference;

    return (
      <div
        className={this.props.className}
        onKeyPress={(e) => handleKeyPress(e, this.search.bind(this))}
      >
        {(specification.isSearchable("sequenceNum") ||
          specification.isSearchable("requestName") ||
          specification.isSearchable("requesterName")) && (
          <div className="row">
            {specification.isSearchable("sequenceNum") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.ReportId")}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.sequenceNum || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeSequenceNum,
                  )}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("requestName") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.ReportName")}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.title || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeTitle,
                  )}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("requesterName") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.applicantName")}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.applicantName || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeApplicantName,
                  )}
                />
              </LabeledComponent>
            )}
          </div>
        )}
        {specification.isSearchable("belongingDepartment") && (
          <div className="row">
            <LabeledComponent
              className="col-sm-6 col-lg-3"
              label={i18next.t("reports.searchConditions.applicantDepartment")}
            >
              <div className="list-selector form-control">
                <DepartmentSelector
                  departments={this.props.searchCondition.groups.current}
                  selectedDepartment={
                    _isNil(this.props.searchCondition.departmentId)
                      ? null
                      : this.props.searchCondition.group
                  }
                  onSelectDepartment={(department) =>
                    this.props.onSelectGroup(null, {
                      suggestion: department,
                      suggestionValue: department,
                      method: null,
                    })
                  }
                />
              </div>
            </LabeledComponent>
            <LabeledComponent
              className="col-sm-6 col-md-6 col-lg-3"
              label={i18next.t(
                "transactions.index.searchbox.departmentScope.label",
              )}
            >
              <div className="checkbox-group">
                <SmartCheckBox
                  label={i18next.t(
                    "transactions.index.searchbox.departmentScope.includeChildDepartment",
                  )}
                  checked={this.props.searchCondition.includeChildDepartment}
                  onChange={this.props.onChangeDepartmentScope}
                  color="accent"
                  type="squared"
                />
              </div>
            </LabeledComponent>
          </div>
        )}
        <div className="row">
          {specification.isSearchable("requestedAt") && (
            <LabeledComponent
              className="col-sm-12 col-lg-6"
              label={i18next.t("reports.searchConditions.requestDate")}
            >
              <PeriodInput
                className="submitted-at-period"
                dateFrom={this.props.searchCondition.submittedAtFrom}
                dateTo={this.props.searchCondition.submittedAtTo}
                onChangeDateFrom={this.props.onChangeSubmittedAtFrom}
                onChangeDateTo={this.props.onChangeSubmittedAtTo}
              />
            </LabeledComponent>
          )}
          {specification.isSearchable("lastApprovedAt") && (
            <LabeledComponent
              className="col-sm-12 col-lg-6"
              label={i18next.t("reports.searchConditions.lastApprovedDate")}
            >
              <PeriodInput
                className="lastapproved-at-period"
                dateFrom={this.props.searchCondition.lastApprovedAtFrom}
                dateTo={this.props.searchCondition.lastApprovedAtTo}
                onChangeDateFrom={this.props.onChangeLastApprovedAtFrom}
                onChangeDateTo={this.props.onChangeLastApprovedAtTo}
              />
            </LabeledComponent>
          )}
        </div>
        <div className="row">
          {specification.isSearchable("transactedAt") && (
            <LabeledComponent
              className="col-sm-12 col-lg-6"
              label={i18next.t("reports.searchConditions.optionalDate")}
            >
              <PeriodInput
                className="transacted-at-period"
                dateFrom={this.props.searchCondition.transactedAtFrom}
                dateTo={this.props.searchCondition.transactedAtTo}
                onChangeDateFrom={this.props.onChangeTransactedAtFrom}
                onChangeDateTo={this.props.onChangeTransactedAtTo}
              />
            </LabeledComponent>
          )}
          {specification.isSearchable("invoiceDue") && (
            <InvoiceDueAtRangeField
              className="col-sm-12 col-lg-6"
              dueFrom={this.props.searchCondition.invoice.dueFrom}
              dueTo={this.props.searchCondition.invoice.dueTo}
              onDueFromChange={this.props.onChangeInvoiceDueFrom}
              onDueToChange={this.props.onChangeInvoiceDueTo}
            />
          )}
          {!specification.isSearchable("transactedAt") &&
            specification.isSearchable("invoiceSchedule") && (
              <InvoiceScheduleAtRangeField
                className="col-sm-12 col-lg-6"
                scheduleFrom={this.props.searchCondition.invoice.scheduleFrom}
                scheduleTo={this.props.searchCondition.invoice.scheduleTo}
                onScheduleFromChange={this.props.onChangeInvoiceScheduleFrom}
                onScheduleToChange={this.props.onChangeInvoiceScheduleTo}
              />
            )}
        </div>
        {(specification.isSearchable("invoiceSchedule") ||
          specification.isSearchable("invoiceAppropriated")) && (
          <div className="row">
            {
              // 利用日が使える場合、前の行が埋まっているのでここに表示する
              specification.isSearchable("transactedAt") &&
                specification.isSearchable("invoiceSchedule") && (
                  <InvoiceScheduleAtRangeField
                    className="col-sm-12 col-lg-6"
                    scheduleFrom={
                      this.props.searchCondition.invoice.scheduleFrom
                    }
                    scheduleTo={this.props.searchCondition.invoice.scheduleTo}
                    onScheduleFromChange={
                      this.props.onChangeInvoiceScheduleFrom
                    }
                    onScheduleToChange={this.props.onChangeInvoiceScheduleTo}
                  />
                )
            }
            {specification.isSearchable("invoiceAppropriated") && (
              <InvoiceAppropriatedAtRangeField
                className="col-sm-12 col-lg-6"
                appropriatedFrom={
                  this.props.searchCondition.invoice.appropriatedFrom
                }
                appropriatedTo={
                  this.props.searchCondition.invoice.appropriatedTo
                }
                onAppropriatedFromChange={
                  this.props.onChangeInvoiceAppropriatedFrom
                }
                onAppropriatedToChange={
                  this.props.onChangeInvoiceAppropriatedTo
                }
              />
            )}
          </div>
        )}
        <hr />
        {(specification.isSearchable("approvalFlowName") ||
          specification.isSearchable("includingApprover") ||
          specification.isSearchable("approvedApprover")) && (
          <div className="row">
            {specification.isSearchable("approvalFlowName") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.approvalFlowName")}
              >
                <AutoSuggestWrapper
                  isImmutable
                  value={this.props.searchCondition.approvalFlowName}
                  suggestions={this.props.searchCondition.approvalFlows.current}
                  renderSuggestion={renderApprovalFlow}
                  onSuggestionsUpdateRequested={
                    this.props.onApprovalFlowSuggestionsUpdateRequested
                  }
                  onTextChanged={this.props.onChangeApprovalFlowName}
                  onSuggestionSelected={this.props.onSelectApprovalFlow}
                  clearValue={this.props.onClearApprovalFlowName}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("includingApprover") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t(
                  "reports.searchConditions.IncludedInApprovalFlow",
                )}
              >
                <AutoSuggestWrapper
                  isImmutable
                  value={this.props.searchCondition.approverName}
                  suggestions={this.props.searchCondition.approvers.current}
                  renderSuggestion={renderApprover}
                  onSuggestionsUpdateRequested={
                    this.props.onApproverSuggestionsUpdateRequested
                  }
                  onTextChanged={this.props.onChangeApproverName}
                  onSuggestionSelected={this.props.onSelectApprover}
                  clearValue={this.props.onClearApproverName}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("approvedApprover") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.approvedUser")}
              >
                <AutoSuggestWrapper
                  isImmutable
                  value={this.props.searchCondition.approvedApproverName}
                  suggestions={
                    this.props.searchCondition.approvedApprovers.current
                  }
                  renderSuggestion={renderApprover}
                  onSuggestionsUpdateRequested={
                    this.props.onApprovedApproverSuggestionsUpdateRequested
                  }
                  onTextChanged={this.props.onChangeApprovedApproverName}
                  onSuggestionSelected={this.props.onSelectApprovedApprover}
                  clearValue={this.props.onClearApprovedApproverName}
                />
              </LabeledComponent>
            )}
          </div>
        )}
        {(specification.isSearchable("projectID") ||
          specification.isSearchable("projectName") ||
          specification.isSearchable("invoiceSupplierName") ||
          specification.isSearchable("invoiceSupplierCode")) && (
          <div className="row">
            {specification.isSearchable("projectID") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.projectId")}
              >
                <AutoSuggestWrapper
                  isImmutable
                  value={this.props.searchCondition.projectId}
                  suggestions={this.props.searchCondition.projectIds.current}
                  renderSuggestion={renderText}
                  onSuggestionsUpdateRequested={
                    this.props.onProjectIdSuggestionsUpdateRequested
                  }
                  onTextChanged={this.props.onChangeProjectId}
                  onSuggestionSelected={this.props.onSelectProjectId}
                  clearValue={this.props.onClearProjectId}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("projectName") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={i18next.t("reports.searchConditions.projectName")}
              >
                <AutoSuggestWrapper
                  isImmutable
                  value={this.props.searchCondition.projectName}
                  suggestions={this.props.searchCondition.projectNames.current}
                  renderSuggestion={renderText}
                  onSuggestionsUpdateRequested={
                    this.props.onProjectNameSuggestionsUpdateRequested
                  }
                  onTextChanged={this.props.onChangeProjectName}
                  onSuggestionSelected={this.props.onSelectProjectName}
                  clearValue={this.props.onClearProjectName}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("invoiceSupplierName") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={`${i18next.t(
                  "paymentRequests.properties.supplierName",
                )}（${i18next.t("menus.ip.invoices")}）`}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.invoice.supplierName || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeInvoiceSupplierName,
                  )}
                />
              </LabeledComponent>
            )}
            {specification.isSearchable("invoiceSupplierCode") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={`${i18next.t(
                  "paymentRequests.properties.supplierCode",
                )}（${i18next.t("menus.ip.invoices")}）`}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.invoice.supplierCode || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeInvoiceSupplierCode,
                  )}
                />
              </LabeledComponent>
            )}
          </div>
        )}
        <div className="row">
          {specification.isSearchable("categoryId") && (
            <LabeledComponent
              className="col-sm-6 col-lg-3"
              label={i18next.t("reports.searchConditions.category")}
            >
              <CategorySuggestField
                text={this.props.searchCondition.categoryName}
                value={
                  {
                    id: this.props.searchCondition.categoryId,
                    name: this.props.searchCondition.categoryName,
                  } || null
                }
                onSelect={(c) => this.props.onSelectCategoryId(c)}
                showParentCategory={false}
              />
            </LabeledComponent>
          )}
        </div>
        {this.props.searchCondition?.genericFields &&
          this.props.searchCondition?.genericFields.length > 0 && (
            <div
              className="row"
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              {genericFields.map((dataSet, index) => {
                const searchConditionGFByCode =
                  this.props.searchCondition.genericFields.find(
                    (gf) => gf.id === dataSet.id && gf.type === "code",
                  );
                const searchConditionGFByName =
                  this.props.searchCondition.genericFields.find(
                    (gf) => gf.id === dataSet.id && gf.type === "name",
                  );

                if (!searchConditionGFByCode || !searchConditionGFByName) {
                  return <></>;
                }

                return (
                  <Fragment key={index}>
                    <LabeledComponent
                      className="col-sm-6 col-lg-3"
                      label={`${dataSet.name}(${i18next.t(
                        "paymentRequests.properties.genericFields.dataSet.item.code",
                      )})`}
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <input
                        className="form-control"
                        value={searchConditionGFByCode.value || ""}
                        onChange={this.handleInput.bind(null, (value) =>
                          this.props.onChangeGenericFieldCode({
                            id: dataSet.id,
                            type: "code",
                            value,
                          }),
                        )}
                      />
                    </LabeledComponent>
                    <LabeledComponent
                      className="col-sm-6 col-lg-3"
                      label={`${dataSet.name}(${i18next.t(
                        "paymentRequests.properties.genericFields.dataSet.item.name",
                      )})`}
                      style={{ paddingTop: "8px", paddingBottom: "8px" }}
                    >
                      <input
                        className="form-control"
                        value={searchConditionGFByName.value || ""}
                        onChange={this.handleInput.bind(null, (value) =>
                          this.props.onChangeGenericFieldName({
                            id: dataSet.id,
                            type: "name",
                            value,
                          }),
                        )}
                      />
                    </LabeledComponent>
                  </Fragment>
                );
              })}
            </div>
          )}
        {
          <div className="row">
            {
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={`${i18next.t(
                  "paymentRequests.properties.registratedNumber",
                )}`}
              >
                <input
                  className="form-control"
                  value={
                    this.props.searchCondition.invoice.registratedNumber || ""
                  }
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeInvoiceRegistratedNumber,
                  )}
                />
              </LabeledComponent>
            }
            {
              <div className="col-sm-4 col-md-4">
                <AsInvoiceChecksCheckBoxGroupField
                  asInvoiceChecks={
                    this.props.searchCondition.invoice.asInvoiceChecks || []
                  }
                  onChange={this.props.onChangeInvoiceAsInvoiceChecks}
                />
              </div>
            }
            {specification.isSearchable("invoiceReportLabel") && (
              <div className="col-sm-6">
                <ReportLabelsSearchField
                  values={
                    this.props.searchCondition.invoice.reportLabelIds || []
                  }
                  checkboxValue={
                    this.props.searchCondition.invoice.exclusionLabel
                  }
                  onChange={this.props.onChangeInvoiceReportLabelIds}
                  onChangeCheckbox={this.props.onChangeInvoiceExclusionLabel}
                  label={`${i18next.t(
                    "paymentRequests.properties.reportLabel",
                  )}（${i18next.t("menus.ip.invoices")}）`}
                />
              </div>
            )}
          </div>
        }
        {(specification.isSearchable("approvalStatus") ||
          specification.isSearchable("invoiceMemo")) && (
          <div className="row">
            <div
              className="col-sm-6"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <RequestStatusCheckBoxes
                statusForReport={this.props.searchCondition.status.forReport}
                statusForApprover={
                  this.props.searchCondition.status.forApprover
                }
                disabledStatusForApprover={
                  this.props.searchCondition.disabledStatusForApprover
                }
                onChangeStatus={this.props.onChangeStatus}
                onChangeAllStatus={this.props.onChangeAllStatus}
                onChangeStatusForApprover={this.props.onChangeStatusForApprover}
                forApprover={this.props.forApprover}
                usedStatusesForReport={specification.searchableApprovalStatuses()}
              />
            </div>
            {specification.isSearchable("invoiceMemo") && (
              <LabeledComponent
                className="col-sm-6 col-lg-3"
                label={`${i18next.t(
                  "paymentRequests.properties.memo",
                )}（${i18next.t("menus.ip.invoices")}）`}
              >
                <input
                  className="form-control"
                  value={this.props.searchCondition.invoice.memo || ""}
                  onChange={this.handleInput.bind(
                    null,
                    this.props.onChangeInvoiceMemo,
                  )}
                />
              </LabeledComponent>
            )}
          </div>
        )}
        {(specification.isSearchable("requestType") ||
          specification.isSearchable("paperlessStatus") ||
          specification.isSearchable("entire")) && (
          <div className="row">
            {specification.isSearchable("requestType") && (
              <CheckBoxGroup
                className="col-sm-6"
                checkBoxesProps={Object.keys(
                  this.props.searchCondition.requestType,
                ).map((x) => ({
                  color: "primary",
                  label: this.props.searchCondition.requestType[x].name,
                  checked: this.props.searchCondition.requestType[x].checked,
                  onChange: this.props.onChangeRequestType.bind(null, x),
                }))}
                label={i18next.t("reports.titles.kinds")}
                isAllSelectable={false}
              />
            )}
            {specification.isSearchable("paperlessStatus") && (
              <PaperlessStatusCheckBoxes
                checkedMatchedOriginalReceipt={
                  this.props.searchCondition.matchedOriginalReceipt
                }
                checkedNotMatchedOriginalReceipt={
                  this.props.searchCondition.notMatchedOriginalReceipt
                }
                onChangeMatchedOriginalReceipt={
                  this.props.onChangeMatchedOriginalReceipt
                }
                onChangeNotMatchedOriginalReceipt={
                  this.props.onChangeNotMatchedOriginalReceipt
                }
              />
            )}
            {specification.isSearchable("entire") && (
              <CheckBoxGroup
                className="col-sm-6"
                checkBoxesProps={[
                  {
                    color: "accent",
                    label: i18next.t("reports.index.scope.all"),
                    checked: this.props.searchCondition.scope === "admin",
                    onChange: () =>
                      this.props.onChangeScope(
                        this.props.searchCondition.scope === "admin"
                          ? null
                          : "admin",
                      ),
                  },
                ]}
                label={i18next.t("reports.index.scope.label")}
                isAllSelectable={false}
              />
            )}
          </div>
        )}
        {/* 検索ボタンは表示される検索条件の影響を受けないように別行に配置する */}
        <div className="row">
          <div className="col-md-offset-8 col-md-4">
            <div className="text-right">
              <a
                onClick={this.reset.bind(this)}
                className={`anchor-reset-condition ${
                  isLoading ? "disabled" : ""
                }`}
              >
                {i18next.t("transactions.index.searchbox.resetCondition")}
              </a>
            </div>
            <SearchButton
              className={`btn btn-accent ${isLoading ? "disabled" : ""}`}
              disabled={isLoading ? "disabled" : ""}
              type="button"
              onClick={this.search.bind(this)}
            >
              <span
                className={`fa fa-left ${
                  isLoading ? "fa-spinner fa-spin" : "fa-search"
                }`}
              ></span>
              {i18next.t("transactions.index.searchbox.search")}
            </SearchButton>
          </div>
        </div>
      </div>
    );
  }
}

RequestSearchBox.defaultProps = {
  className: "request-searchbox",
  forApprover: false,
  specification: new RequestSearchSpecification(false),
  onChangeMatchedOriginalReceipt: () => {},
  onChangeNotMatchedOriginalReceipt: () => {},
  onInitSearchConditions: () => {},
};

RequestSearchBox.propTypes = {
  className: PropTypes.string,
  forApprover: PropTypes.bool.isRequired,
  specification: PropTypes.object.isRequired,
  onChangeAllStatus: PropTypes.func.isRequired,
  onChangeDepartmentScope: PropTypes.func,
  onChangeLastApprovedAtFrom: PropTypes.func.isRequired,
  onChangeLastApprovedAtTo: PropTypes.func.isRequired,
  onChangeInvoiceDueFrom: PropTypes.func.isRequired,
  onChangeInvoiceDueTo: PropTypes.func.isRequired,
  onChangeMatchedOriginalReceipt: PropTypes.func.isRequired,
  onChangeNotMatchedOriginalReceipt: PropTypes.func.isRequired,
  onChangeRequestType: PropTypes.func.isRequired,
  onChangeScope: PropTypes.func,
  onChangeSequenceNum: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onChangeSubmittedAtFrom: PropTypes.func.isRequired,
  onChangeSubmittedAtTo: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func.isRequired,
  onChangeTransactedAtFrom: PropTypes.func.isRequired,
  onChangeTransactedAtTo: PropTypes.func.isRequired,
  onInitSearchConditions: PropTypes.func,
  onChangeInvoiceRegistratedNumber: PropTypes.func,
  onChangeInvoiceAsInvoiceChecks: PropTypes.func,
  onResetSearchConditions: PropTypes.func.isRequired,
  saveSearchConditions: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  searchCondition: PropTypes.shape({
    requestTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  // 申請者では使わない
  onApprovedApproverSuggestionsUpdateRequested: PropTypes.func,
  onApproverSuggestionsUpdateRequested: PropTypes.func,
  onChangeApplicantName: PropTypes.func,
  onChangeApprovedApproverName: PropTypes.func,
  onChangeApproverName: PropTypes.func,
  onChangeProjectId: PropTypes.func,
  onChangeProjectName: PropTypes.func,
  onClearProjectId: PropTypes.func,
  onClearProjectName: PropTypes.func,
  onGroupSuggestionsUpdateRequested: PropTypes.func,
  onProjectIdSuggestionsUpdateRequested: PropTypes.func,
  onProjectNameSuggestionsUpdateRequested: PropTypes.func,
  onSelectApprovedApprover: PropTypes.func,
  onSelectApprover: PropTypes.func,
  onSelectGroup: PropTypes.func,
  onSelectProjectId: PropTypes.func,
  onSelectProjectName: PropTypes.func,
  onChangeInvoiceSupplierName: PropTypes.func,
  onChangeInvoiceSupplierCode: PropTypes.func,
  onChangeInvoiceMemo: PropTypes.func,
  onChangeInvoiceAppropriatedFrom: PropTypes.func,
  onChangeInvoiceAppropriatedTo: PropTypes.func,
};
