// 検索フィールドでEnterキーを押したときに検索を実行するための処理。
// ここでは react-autosuggest の suggestion が選択されたときに検索が行われないようにしている。

import React from "react";

let useEnter = false;

export function handleSuggestionSelected(method: string): void {
  if (method === "enter") {
    useEnter = true;
  }
}

export function handleKeyPress(
  event: React.KeyboardEvent,
  callback: () => void,
): void {
  if (
    event.key === "Enter" &&
    (event.target as HTMLInputElement).tagName === "INPUT"
  ) {
    if (useEnter) {
      useEnter = false;
      return;
    }
    event.preventDefault();
    callback();
  }
}
