import ApiBase from "./api_base";

let authPath = "/api/v1/auth";

if (global.userPreferences && global.userPreferences.agent) {
  authPath = "/api/v1/agent_auth";
}

export default new ApiBase("v1", { authPath })
  .resources("/users/multi_tenants", { only: ["index"] })
  .destroy("/omniauth/{provider}", { to: "omniauth.destroy" })

  .resources("/departments", { only: ["index"] })
  .resources("/analysis_report_xls_jobs", { only: ["index", "create"] })
  .put("/analysis_report_xls_jobs/exported")
  .resources("/export_jobs", { only: ["index", "create"] })
  .resources("/import_jobs", { only: ["index", "create"] })
  .get("/import_jobs/check_queue")
  .get("/import_jobs/upload_url")
  .resources("/member_concerns_export_jobs", { only: ["index", "create"] })
  .resources("/root_category_concerns_export_jobs", {
    only: ["index", "create"],
  })
  .resources("/metadata_export_jobs", { only: ["index", "create"] })
  .resources("/external_tables", { only: ["index"] })
  .get("/expense_data_entries/waiting_time")
  .get("/external_tables/import_jobs")
  .post("/external_tables/start_import")
  .get("/external_tables/check_queue")
  .get("/external_tables/upload_url")
  .resources("/transactions")
  // パラメータが長くなる（i.e. transaction_idsを指定）ので、POSTする
  .post("/transactions", {
    to: "transactions.index",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .put("/transactions/{id}/notify_image_uploaded")
  .get("/transactions/recent_shops")
  .put("/transactions/merge")
  .get("/transactions/monthly_worker_input_usage/{year}")
  .get("/transactions/amount_per_tax_categories/initialize")
  .get("/transactions/selectable_tax_categories")
  .put("/transactions/{id}/attach_original_receipt_image")
  .put("/transactions/{id}/mark_as_matched")
  .post("/transactions/{id}/cancel_marked_as_matched")
  .destroy("/transactions", { to: "transactions.destroy_all" })
  .get("/expenses/{id}/histories")
  .put("/expenses/{id}/split")

  .resources("/expense_timestamp_verification_exports", { only: ["index"] })
  .post("/expense_timestamp_verification_exports/create_by_search")
  .post("/expense_timestamp_verification_exports/create_by_select")

  .get("/reports/{reportId}/histories")
  .get("/reports/{reportId}/expenses")

  .resources("/expenses/reports")
  .get("/expenses/reports/open_titles")
  .put("/expenses/reports/title")
  .post("/expenses/reports/{id}/request")
  .put("/expenses/reports/{id}/detach")
  .post("/expenses/reports/export")
  .post("/expenses/reports/report_export_jobs")
  .get("/expenses/reports/exported_csvs")
  .put("/expenses/reports/{id}/attach")

  .get("/expenses/pre_reports/exported_csvs")
  .post("/expenses/pre_reports/csv_export_jobs")

  .get("/expenses/applications/request_types/{request_type_id}/exported_csvs")
  .post("/expenses/applications/csv_export_jobs")

  .resources("/currencies", { only: ["index"] })

  .resources("/pre_reports")
  .post("/pre_reports/{id}/record_temporary_payment")
  .put("/pre_reports/{id}/cancel_temporary_payment")
  .post("/pre_reports/{id}/record_temporary_payment_refund")
  .post("/pre_reports/{id}/cancel_temporary_payment_refund")
  .put("/pre_reports/{id}/detach")
  .get("/pre_reports/{preReportId}/expenses")

  .resources("/temporary_payments", { only: ["index"] })
  .get("/temporary_payments/pending")
  .get("/temporary_payments/pending_refund")

  .resources("/payee_accounts", { only: ["index"] })

  .resources("/s3_files", { only: ["show", "update", "destroy"] })
  .get("/s3_files/presigned_url/{id}")

  .resources("/receipt_images", { only: ["index", "get", "destroy"] })
  .post("/receipt_images/upload_url")

  .resources("/request_types", {
    only: ["index", "show", "create", "update", "destroy"],
  })
  .resources("/pre_report_preferences", { only: ["create", "update"] })
  .resources("/requests", { only: ["create", "show", "destroy"] })
  .get("/requests/search")
  .get("/requests/approval_flows")
  .post("/requests/{id}/reapply")
  .post("/requests/{id}/reject")
  .post("/requests/{id}/approve")
  .post("/requests/{id}/comment")
  .post("/requests/{id}/cancel_approvals")
  .post("/requests/paid_batch")

  .resources("/bulk_approving_request/preferences", { only: ["index"] })
  .put("/bulk_approving_request/preferences/update")

  .resources("/applications", { only: ["show", "destroy"] })
  .resources("/application_requests", { only: ["create"] })
  .get("/application_requests/request")
  .get("/requests/reports/{id}/neighbors")
  .get("/requests/pre_reports/{id}/neighbors")
  .get("/requests/applications/{id}/neighbors")

  .resources("/approval_flows", {
    only: ["index", "show", "create", "update", "destroy"],
  })
  .post("/approval_flows/attach")
  .post("/approval_flows/copy")
  .get("/approval_flows/names")
  .post("/approval_flows/check_diff", {
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .resources("/approval_flows/problem_export_jobs", {
    only: ["index", "create"],
  })
  .post("/report_requests/reports")
  .post("/report_requests/split")
  .post("/report_requests/request")
  .post("/report_requests/cancel_approvals")

  .get("/pre_report_requests/requests")
  .post("/pre_report_requests/request")

  .put("/approvals/{id}/approve")
  .put("/approvals/{id}/reject")
  .get("/approvals/{id}/approval_steps")

  .resources("/approvers", { only: ["index"] })

  .get("/notifications/count")
  .put("/notifications/{id}/resolve")
  .resources("/original_receipt_identification_requests", { only: ["index"] })
  .post("/original_receipts/identification_requests/{id}/read")
  .post("/original_receipts/identification_requests/notify")
  .get("/original_receipts/identification_requests/count")

  .resources("/members", {
    only: ["index"],
  })
  .put("/members/{id}/blocked")
  .put("/members/{id}/unblocked")
  .put("/members/{id}/user")
  .post("/members/{id}/lock/expense_account", {
    to: "members.lockExpenseAccount",
  })
  .post("/members/{id}/lock/teiki", { to: "members.lockTeiki" })
  .post("/members/{id}/unlock_access")
  .post("/members/start_import")
  .get("/members/approval_flow_unassignment_existences")
  .get("/members/index_for_approval_flow_assignment")
  .get("/members/{member_id}/departments", { to: "members.departments.index" })
  .resources("/posts", { only: ["index", "create", "update", "destroy"] })
  .resources("/groups", {
    only: ["index", "create", "update", "destroy"],
  })
  .resources("/groups/departments_posts_approvers_table_export_jobs", {
    only: ["index", "create"],
  })
  .put("/groups/{id}/posts")
  .get("/invoices")

  .get("/transits/suggest")
  .get("/transits/recent_shops")
  .get("/transits/recent_stations")
  .get("/transits/route_summaries")
  .get("/transits/teiki_summaries")

  .resources("/preferences", { only: ["index"] })
  .put("/preferences", { to: "preferences.update" })
  .get("/preferences/export_category_rules")
  .post("/preferences/default_report_name", {
    options: { headers: { "X-Http-Method-Override": "GET" } },
  })
  .put("/preferences/report_name_format")
  .post("/preferences/default_pre_report_name", {
    options: { headers: { "X-Http-Method-Override": "GET" } },
  })
  .put("/preferences/pre_report_name_format")
  .put("/preferences/default_transit_category")
  .put("/preferences/default_currency")
  .put("/preferences/exchange_rate", { to: "exchangeRate.update" })
  .post("/preferences/lockable")
  .put("/preferences/update_enable_report_draft_save")
  .resources("/preferences/exchange_rate", { only: ["destroy"] })
  .resources("/sso_available_root_groups", {
    only: ["index", "create", "destroy"],
  })

  .resources("/alert_rule_preferences", { only: ["index", "update"] })

  .put("/preferences/update_cutoff_day")

  .post("/preferences/start_import")
  .post("/preferences/import_members")
  .get("/preferences/list_options")
  .put("/preferences/update_list_options")
  .put("/preferences/update_export_aggregation_columns")
  .put("/preferences/update_exported_request_file_display_group_name")
  .get("/importers")

  .resources("/closing_dates", {
    only: ["index", "create", "update", "destroy"],
  })

  .get("/withholdings/calculation_constants")

  .resources("/e_doc_options", { only: ["index", "update"] })

  .post("/accounting/journal_entries/export/email_for_expenses", {
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })

  .get("/journal_entries_export/{id}/download_preview")
  .post("/journal_entries/export_formats/from_template")
  .put("/journal_entries/export_formats/{id}/restore")
  .put("/journal_entries/export_formats/{id}/duplicate")
  .put("/journal_entries/export_formats/{id}/update_default_format")
  .get("/journal_entries/export_formats/{export_format_id}/preference")
  .put(
    "/journal_entries/export_formats/{export_format_id}/update_export_target",
  )
  .put(
    "/journal_entries/export_formats/{export_format_id}/update_debit_credit_style",
  )
  .put(
    "/journal_entries/export_formats/{export_format_id}/update_display_sample_data_in_export_contents",
  )
  .put(
    "/journal_entries/export_formats/{export_format_id}/update_accounting_voucher_preference",
  )
  .resources("/journal_entries/export_formats")
  .resources(
    "/journal_entries/export_formats/{export_format_id}/cell_variable_options",
    { only: ["index"] },
  )
  .resources(
    "/journal_entries/export_formats/{export_format_id}/table_contents",
    { only: ["index"] },
  )
  .resources(
    "/journal_entries/export_formats/{export_format_id}/table_contents_export_jobs",
    { only: ["index", "create"] },
  )
  .resources("/journal_entries/export_formats/{export_format_id}/columns", {
    only: ["show", "create", "update", "destroy"],
  })
  .resources("/journal_entries/export_formats/{export_format_id}/cells", {
    only: ["show", "update"],
  })
  .resources("/journal_entries/accounting_softwares", { only: ["index"] })
  .resources("/journal_entries/aggregation_condition_options", {
    only: ["index"],
  })
  .resources("/journal_entries/aggregation_unit_options", { only: ["index"] })
  .resources("/journal_entries/accounting_voucher_division_unit_options", {
    only: ["index"],
  })
  .resources("/journal_entries/cell_value_condition_options", {
    only: ["index"],
  })
  .resources("/journal_entries/export_target_condition_options", {
    only: ["index"],
  })

  .get("/users/me", { to: "users.me.index" })
  .put("/users/me", { to: "users.me.update" })

  .resources("/projects", {
    only: ["index", "show", "create", "destroy", "update"],
  })
  .put("/projects/{id}/update")
  .get("/projects/display_id_candidates")
  .get("/projects/name_candidates")

  .resources("/project_export_jobs", { only: ["index", "create"] })

  .resources("/project_import_jobs", { only: ["create"] })
  .get("/project_import_jobs/upload_url")

  .resources("/agents", { only: ["index", "create", "update", "destroy"] })
  .get("/agents/candidates")
  .get("/agents/principals")

  .post("/projects/{id}/assign")
  .resources("/companions", { only: ["index", "create", "update", "destroy"] })
  .put("/companions/{id}/update")

  .resources("/analyses", { only: ["get", "show", "create", "destroy"] })
  .post("/analyses/aggregate")
  .post("/analyses/target_reports", {
    to: "analyses.targetReports",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .get("/analyses/validate_zengin")
  .get("/analyses/summaries_by_category")
  .get("/analyses/summaries_by_user")
  .get("/analyses/reports_summaries")
  .put("/analyses/{id}/title")

  .resources("/pre_analyses", { only: ["get", "show", "create", "destroy"] })
  .post("/pre_analyses/aggregate")
  .post("/pre_analyses/target_reports", {
    to: "preAnalyses.targetReports",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .get("/pre_analyses/validate_zengin")
  .get("/pre_analyses/summaries_by_category")
  .get("/pre_analyses/summaries_by_user")
  .get("/pre_analyses/pre_reports_summaries")
  .put("/pre_analyses/temporary_paid")
  .put("/pre_analyses/{id}/title")

  .post("/import/parse", {
    options: {
      processData: false,
      contentType: false,
    },
  })

  .resources("/banks", { only: ["index"] })
  .get("/banks/branches")

  .resources("/expense_accounts", {
    only: ["index", "update", "create", "destroy"],
  })

  .resources("/company_expense_accounts", {
    only: ["index", "update", "create", "destroy"],
  })

  .resources("/csv_converters", { only: ["index"] })
  .resources("/root_categories")
  .resources("/personal_categories", { only: ["index", "update"] })
  .put("/kernels/authenticates/password", {
    to: "kernels.authenticates.password.update",
  })
  .resources("/kernels/organizations/reorganizations/changes", {
    only: ["index", "show", "create", "destroy"],
  })
  .resources(
    "/kernels/organizations/reorganizations/changes/{change_id}/department_deletions",
    {
      only: ["index", "show", "create", "destroy"],
    },
  )
  .resources(
    "/kernels/organizations/reorganizations/changes/{change_id}/employee_changes",
    {
      only: ["index"],
    },
  )
  .resources(
    "/kernels/organizations/reorganizations/changes/{change_id}/employees",
    {
      only: ["index", "create", "destroy"],
    },
  )
  .resources(
    "/kernels/organizations/reorganizations/changes/{change_id}/jobs",
    {
      only: ["create"],
    },
  )
  .resources(
    "/kernels/organizations/reorganizations/changes/{change_id}/schedules",
    {
      only: ["create"],
    },
  )
  .destroy(
    "/kernels/organizations/reorganizations/changes/{change_id}/schedule",
    {
      to: "kernels.organizations.reorganizations.changes.schedule.destroy",
    },
  )
  .get("/kernels/organizations/reorganizations/changes/status")
  .resources("/kernels/super_categories", {
    only: ["index", "create", "update", "destroy"],
  })
  .post("/kernels/super_categories/import_csv", {
    options: { headers: { "Content-Type": "multipart/form-data" } },
  })
  .post("/kernels/super_categories/import_excel", {
    options: { headers: { "Content-Type": "multipart/form-data" } },
  })
  .get("/kernels/super_categories/export_csv", {
    options: { responseType: "blob" },
  })
  .get("/kernels/super_categories/export_excel", {
    options: { responseType: "blob" },
  })
  .get("/kernels/super_categories/sample_csv_export", {
    options: { responseType: "blob" },
  })
  .get("/kernels/super_categories/sample_excel_export", {
    options: { responseType: "blob" },
  })
  .resources("/sub_categories", { except: ["show"] })
  .resources("/business_categories", { only: ["index", "create"] })
  .put("/tax_categories/deleted/put_back")
  .get("/tax_categories/deleted/index")
  .resources("/tax_categories", {
    only: ["index", "create", "update", "destroy"],
  })

  .post("/request_events/comment")
  .resources("/user_preferences", { only: ["index"] })
  .resource("/user_preference", { only: ["show"] })
  .resources("/user_preferences/companions")
  .post("/user_preferences/notifications")

  .resources("/teiki_routes", { only: ["create", "update", "destroy"] })

  .resources("/users/invite", { only: ["create"] })

  .resources("/aggregations", { only: ["update", "create", "destroy"] })
  .post("/aggregations/reload_request")

  .resources("/aggregation_results", { only: ["index"] })
  .destroy("/aggregation_results", { to: "aggregationResults.destroy" })
  .get("/aggregation_results/mergeable")
  .post("/aggregation_results/transactions")
  .resources("/accounts", { only: ["index"] })

  .resources("/account_forms", { only: ["index", "show"] })
  .get("/account_forms/{id}/edit")
  .get("/account_forms/{id}/additional_input")
  .get("/account_forms/{id}/new", { to: "accountForms.new" })
  .post("/account_forms/{id}/new", { to: "accountForms.create" })

  .resources("/external_category_maps", { only: ["update"] })
  .resources("/external_tax_category_maps", { only: ["update"] })

  .resources("/currency_preferences", { only: ["index"] })
  .post("/currency_preferences/start_import")
  .post("/currency_preferences/exchange_rate_histories")

  .resource("/requestable_period", { only: ["show", "create", "update"] })

  .resources("/tags", { only: ["index", "create", "update", "destroy"] })
  .post("/tags/update_user")

  .resources("/direct_product_tables", {
    only: ["show", "index", "create", "update", "destroy"],
  })
  .get("/direct_product_tables/calculation_formula/{id}")

  .resources("/category_amount_limits", {
    only: ["index", "create", "update", "destroy"],
  })

  .resources("/auto_categorize_rule", { only: ["show", "update", "create"] })
  .destroy("/auto_categorize_rule", { to: "autoCategorizeRule.destroy" })

  // Array が Hash 化されるのを防ぐため、POSTする (GET だと 送信時にHash化される)
  .post("/analyses", {
    to: "analyses.index",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .post("/pre_analyses", {
    to: "preAnalyses.index",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })

  // boolean が文字列にならないように POST で送信する
  .post("/mergeable_transactions", {
    to: "mergeableTransactions.index",
    options: { headers: { "X-HTTP-Method-Override": "GET" } },
  })
  .post("/mergeable_transactions/merge")

  .resources("/metadata_keys", {
    only: ["index", "update", "create", "destroy"],
  })
  .resources("/metadata_cells", { only: ["update", "create"] })
  .resources("/new_metadata_cells", { only: ["index"] })
  .resources("/entry_forms", { only: ["index", "show"] })
  .resources("/metadata_cells_users", { only: ["index"] })
  .resources("/metadata_cells_tags", { only: ["index"] })
  .resources("/metadata_cells_company_posts", { only: ["index"] })
  .resources("/metadata_cells_groups", { only: ["index"] })
  .resources("/metadata_cells_projects", { only: ["index"] })
  .resources("/metadata_cells_categories", { only: ["index"] })
  .resources("/metadata_cells_super_categories", { only: ["index"] })
  .resources("/metadata_cells_tax_categories", { only: ["index"] })
  .resources("/metadata_cells_company_expense_accounts", { only: ["index"] })
  .resources("/metadata_cells_payment_requests_debit_invoice_categories", { only: ["index"] })
  .resources("/metadata_cells_payment_requests_credit_invoice_categories", { only: ["index"] })
  .resources("/metadata_cells_payment_requests_suppliers", { only: ["index"] })
  .resources("/metadata_cells_generic_fields_data_set", { only: ["index"] })

  // レポートの既読状態を管理する
  .post("/transaction_read_marks/mark_as_read")
  .post("/transaction_read_marks/mark_as_unread")

  .get("/exports/csv")

  .resources("/corporate_card_holders", { only: ["index"] })
  .get("/corporate_card_holders/{id}/aggregation_results")
  .post("/corporate_card_holders/start_import")
  .resources("/corporate_cards", { only: ["index"] })
  .get("/corporate_cards/status")

  .resources("original_receipts", { only: ["index"] })
  .post("/original_receipts/{id}/match")
  .post("/original_receipts/{id}/cancel_match")

  .resources("/receipt_matching_result_report_export_jobs", {
    only: ["index", "create"],
  })

  .get("/activity_logs")
  .resources("/activity_log_export_jobs", { only: ["index", "create"] })

  .resources("/paperless_plan_subscriptions", { only: ["index"] })
  .resources("/receipt_boxes", { only: ["index"] })
  .resources("/auto_input_edit_rule_preference", { only: ["index", "create"] })

  .get("/preferences/search", { to: "preferences.search.show" })
  .post("/preferences/search", { to: "preferences.search.create" })

  .get("/e_doc_option_2022", { to: "eDocOption2022.show" })
  .put("/e_doc_option_2022", { to: "eDocOption2022.update" })

  .resources(
    "/accounting/accounting_data_scheduled_exports/company_expense_accounts",
    { only: ["index"] },
  )

  .resources("/accounting_data_scheduled_exports/tasks")
  .put("accounting_data_scheduled_exports/tasks/{id}/enable", {
    to: "accountingDataScheduledExports.tasks.enable",
  })
  .put("accounting_data_scheduled_exports/tasks/{id}/disable", {
    to: "accountingDataScheduledExports.tasks.disable",
  })
  .put("accounting_data_scheduled_exports/tasks/{id}/test", {
    to: "accountingDataScheduledExports.tasks.test",
  })
  .put("accounting_data_scheduled_exports/tasks/{id}/execute", {
    to: "accountingDataScheduledExports.tasks.execute",
  })
  .resources("/accounting_data_scheduled_exports/file_transfer_locations")
  .get(
    "/accounting_data_scheduled_exports/file_transfer_locations/{id}/check_connection",
    {
      to: "accountingDataScheduledExports.fileTransferLocations.checkConnection",
    },
  )
  .resources("/accounting_data_scheduled_exports/execution_timetables", {
    only: ["index"],
  })
  .resources("/accounting_data_scheduled_exports/export_formats", {
    only: ["index"],
  })
  .resources("/accounting_data_scheduled_exports/executors", {
    only: ["index"],
  })
  .resources("/accounting_data_scheduled_exports/jobs", { only: ["index"] })
  .put("/accounting_data_scheduled_exports/jobs/{id}/re_execute", {
    to: "accountingDataScheduledExports.jobs.reExecute",
  })

  .resources("/generic_fields/data_sets", { only: ["index"] })
  .get("/generic_fields/data_sets/{data_set_id}/items", {
    to: "genericFields.dataSets.items.index",
  })
  .post("/generic_fields/data_sets/{data_set_id}/items", {
    to: "genericFields.dataSets.items.create",
  })
  .put("/generic_fields/data_sets/{data_set_id}/items/{id}", {
    to: "genericFields.dataSets.items.update",
  })
  .destroy("/generic_fields/data_sets/{data_set_id}/items/{id}", {
    to: "genericFields.dataSets.items.destroy",
  })
  .destroy("/generic_fields/data_sets/{data_set_id}/items/destroy_all", {
    to: "genericFields.dataSets.items.destroyAll",
  })
  .resources("/generic_fields/data_sets/{data_set_id}/items/export_jobs", {
    only: ["index", "create"],
  })
  .resources("/generic_fields/data_sets/{data_set_id}/items/import_jobs", {
    only: ["index", "create"],
  })
  .get("/generic_fields/data_sets/{data_set_id}/items_for_users", {
    to: "genericFields.dataSets.itemsForUsers.index",
  })
  .put("/generic_fields/data_sets/bulk_update_sort")
  .get("/generic_fields/data_sets/{data_set_id}/items_for_users", {
    to: "genericFields.dataSets.itemsForUsers.index",
  })
  .post("/generic_fields/data_sets/{data_set_id}/items_for_users", {
    to: "genericFields.dataSets.itemsForUsers.create",
  })

  .resources("/categories/import_jobs", { only: ["index", "create"] })
  .resources("/categories/export_jobs", { only: ["index", "create"] })
  // @deprecated 新料金プランの計算ロジック修正により 2023/6/14 以降に削除されます。
  .resources("/billings/usages", { only: ["index"] })
  .resources("/billings/contracts", { only: ["index"] })
  .resources("/billings/monthly_summaries", { only: ["index"] })

  .resources("requests/approve_jobs", { only: ["index"] })
  .post("requests/approve_jobs/bulk_create")

  .resources("generic_fields/data_sets")
  .put("generic_fields/data_sets/{id}/enable")
  .put("generic_fields/data_sets/{id}/disable")

  .resources("/custom_access_filters", { only: ["index", "update"] })
  .get("/custom_access_filters/ntd_filters/members", {
    to: "customAccessFilters.members.index",
  })
  .put("/custom_access_filters/ntd_filters/{id}", {
    to: "customAccessFilters.ntdFilters.update",
  })
  .put("/custom_access_filters/ntd_filters/{id}/switch_enabled", {
    to: "customAccessFilters.ntdFilters.switchEnabled",
  })

  // 一番最後に呼ぶ
  .build();
