import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import { isWindows } from "utilities/Utils";
import { useHooks } from "./hooks";

/**
 * ホバー時の文字の太さ
 */
const fontWeight = (isHover: boolean): string => {
  if (isWindows()) return "600";
  if (isHover) return "bold";
  return "500";
};

const Wrapper = styled.div`
  position: relative;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #eeeeee;
  padding: 24px;
  cursor: pointer;
  height: 80px;
`;
interface HeaderOuterProps {
  isHover: boolean;
}
const HeaderOuter = styled.div<HeaderOuterProps>`
  position: absolute;
  pointer-events: none;
  height: 80px;
  top: 0;
  left: 24px;
  display: flex;
  align-items: center;
  color: #00bfa5;
  font-weight: ${(p): string => fontWeight(p.isHover)};
`;
interface ArrowIconProps {
  isHover: boolean;
}
const ArrowIcon = styled.i<ArrowIconProps>`
  color: ${(p): string => (p.isHover ? "#020b16" : "#337ab7")};
`;
const Content = styled.div`
  background-color: rgba(1, 1, 1, 0.02);
  border-bottom: 1px solid #eeeeee;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: white;
  background-color: #00bfa5;
  border: 1px solid #00a68f;
  padding: 4px 16px;
  margin-left: 16px;
  cursor: pointer;
  font-weight: 500;
  pointer-events: auto;

  &:hover {
    opacity: 0.6;
  }
`;
const IconTimes = styled.i`
  margin-right: 8px;
  font-size: 16px;
`;

interface Props {
  children: JSX.Element | JSX.Element[] | string;
  /** リセット可能か */
  canReset: boolean;
  /** リセット押した */
  onClickReset: () => void;
}

/**
 * 検索のアコーディオンボタン
 * bootstrapのcollapseを使用
 * bootstrapだと子要素のイベントバブリングを止められないため部分的に外に出し、pointer-eventsを指定している
 */
export const SearchAccordionButton: React.FC<Props> = (props): JSX.Element => {
  const {
    isOpen,
    isHover,
    targetId,
    onClickHeader,
    onClickResetButton,
    onMouseEnter,
    onMouseEnterButton,
    onMouseLeave,
  } = useHooks(props.onClickReset);

  return (
    <Wrapper>
      <HeaderOuter isHover={isHover}>
        <i className="fa fa-fw fa-search txt-accent" />
        <span className="button-text">
          {i18next.t("paymentRequests.properties.filterBySpecified")}
        </span>

        {props.canReset && (
          <Button
            onClick={onClickResetButton}
            onMouseEnter={onMouseEnterButton}
          >
            <IconTimes className="far fa-times-circle" />
            {i18next.t("commons.actions.resetSearchConditions")}
          </Button>
        )}
      </HeaderOuter>

      <Header
        data-toggle="collapse"
        data-target={`#${targetId}`}
        aria-expanded="false"
        aria-controls={targetId}
        onClick={onClickHeader}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ArrowIcon
          isHover={isHover}
          className={`fa fa-right fa-angle-${isOpen ? "up" : "down"}`}
        />
      </Header>

      <Content className="collapse" id={targetId}>
        {props.children}
      </Content>
    </Wrapper>
  );
};
