import escapeRegExp from "lodash/escapeRegExp";
import get from "lodash/get";
import isNil from "lodash/isNil";
import merge from "lodash/merge";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import React, { Component } from "react";
import AutoSuggest from "react-autosuggest";
import { handleSuggestionSelected } from "utilities/searchKeyHandler";

// @deprecated use SuggestField
export default class AutoSuggestWrapper extends Component {
  constructor(props) {
    super(props);

    const id = uniqueId("auto_suggest-");
    if (!props.isImmutable) {
      this.state = {
        id,
        value: props.value || "",
        suggestions: props.suggestions,
        filtered: props.suggestions,
        selected: null,
      };
    } else {
      this.state = { id };
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    if (!props.isImmutable) {
      const value = isNil(props.value) ? this.state.value : props.value;

      if (value && value.length) {
        this.setState({
          value,
          suggestions: props.suggestions,
          filtered: props.suggestions.filter((v) =>
            AutoSuggestWrapper.defaultFilter(
              value.trim(),
              v,
              this.props.getSuggestionValue,
            ),
          ),
        });
      } else {
        this.setState({
          value,
          suggestions: props.suggestions,
          filtered: props.suggestions,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.value !== nextProps.value ||
      this.props.suggestions !== nextProps.suggestions ||
      this.state.value !== nextState.value ||
      this.state.suggestions !== nextState.suggestions ||
      get(this.props.inputProps, "className") !==
        get(nextProps.inputProps, "className")
    );
  }

  onTextChanged(value) {
    if (this.props.onTextChanged) {
      this.props.onTextChanged(value);
      return;
    }
    if (!this.props.isImmutable) {
      this.setState({ value });
    }
  }

  getSuggestionValue(suggestion) {
    if (this.props.getSuggestionValue) {
      return this.props.getSuggestionValue(suggestion);
    }
    return suggestion.value;
  }

  // @note 旧I/Fに合わせて、reasonを引数に渡しているが、新仕様においては渡されてくることがない
  // ただし、内部的に旧I/Fに合わせて利用することがある（cf. onSuggestionsClearRequested）
  onSuggestionsUpdateRequested({ value, reason }) {
    if (this.props.onSuggestionsUpdateRequested) {
      this.props.onSuggestionsUpdateRequested({ value, reason });
      return;
    }
    if (!this.props.isImmutable) {
      if (reason === "click" || reason === "enter") {
        this.setState({
          filtered: this.state.suggestions,
        });
      } else if (value && value.length) {
        this.setState({
          filtered: this.state.suggestions.filter((v) =>
            AutoSuggestWrapper.defaultFilter(
              value.trim(),
              v,
              this.props.getSuggestionValue,
            ),
          ),
        });
      } else {
        this.setState({ filtered: this.state.suggestions });
      }
    }
  }

  onSuggestionsClearRequested() {
    // 旧I/Fのまま新バージョンを使うためのダミー処理
    this.onSuggestionsUpdateRequested({ value: "", reason: "click" });
  }

  onSuggestionSelected(e, { suggestion, suggestionValue, method }) {
    handleSuggestionSelected(method);
    if (this.props.onSuggestionSelected) {
      this.props.onSuggestionSelected(e, {
        suggestion,
        suggestionValue,
        method,
      });
      return;
    }
    if (!this.props.isImmutable) {
      this.setState({
        value: suggestionValue,
      });
    }
  }

  onKeyDown(e) {
    if (e.which > 40 || e.which < 37) {
      // 矢印キーのみ処理
      return;
    }

    const dom = e.target;
    const asyncFunc = function scrollElem() {
      const focusedElemId = dom.getAttribute("aria-activedescendant");
      // suggestionのクラス名は、末尾が--item-[number]となる
      const numMatch = /--item-(\d+)/g.exec(focusedElemId);

      if (numMatch) {
        const $focusedElem = $(`#${focusedElemId}`);
        const height = $focusedElem.outerHeight(); // heightだと、paddingの値が考慮されない
        const itemNums = +numMatch[1];
        const $listElem = $(
          `.${AutoSuggestWrapper.theme.suggestionsContainer}`,
        );

        // itemの高さが全て等しければ、item数 x 高さで、focusが当たっているitemの、リストの中での相対位置を計算できる
        $listElem.scrollTop(height * itemNums);
      }
    };
    setTimeout(asyncFunc, 0);
  }

  onFocus(e) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  static defaultFilter(text, value, getter = (v) => v) {
    const reg = new RegExp(`.*${escapeRegExp(text)}.*`, "g");
    return getter(value).toString().match(reg) || false;
  }

  getSuggestions() {
    if (!this.props.isImmutable) {
      return this.state.filtered;
    }
    return this.props.suggestions;
  }

  getValue() {
    if (!this.props.isImmutable) {
      return this.state.value || "";
    }
    return this.props.value || "";
  }

  clearValue() {
    if (this.props.clearValue) {
      this.props.clearValue();
      return;
    }
    if (!this.props.isImmutable) {
      this.setState({ value: "" });
    }
  }

  shouldRenderSuggestions(value) {
    if (this.props.shouldRenderSuggestions) {
      return this.props.shouldRenderSuggestions(value);
    }
    return true;
  }

  renderSuggestion(suggestion, { value, valueBeforeUpDown }) {
    if (this.props.renderSuggestion) {
      return this.props.renderSuggestion(suggestion, {
        value,
        valueBeforeUpDown,
      });
    }

    return <span>{this.getSuggestionValue(suggestion)}</span>;
  }

  render() {
    const { id, value, suggestions, filterSuggestion } = this.props;
    const inputProps = {
      className: "form-control react-autosuggest-input",
      key: `autosuggest-${id}`,
      value: this.getValue(),
    };

    if (this.props.disabled) {
      merge(inputProps, { disabled: "disabled", ...this.props.inputProps });
      return (
        <div className={this.props.className}>
          <input {...inputProps} />
        </div>
      );
    }

    merge(inputProps, {
      onChange: (e, { newValue, method }) => {
        this.onTextChanged(newValue);
      },
      onKeyDown: this.onKeyDown.bind(this),
      onFocus: this.onFocus.bind(this),
      type: "text",
      ...this.props.inputProps,
    });
    return (
      <div className={this.props.className} style={this.props.style}>
        <AutoSuggest
          className="autosuggest-small"
          id={this.state.id}
          theme={AutoSuggestWrapper.theme}
          suggestions={this.getSuggestions()}
          getSuggestionValue={(v) => {
            if (v !== undefined) return this.getSuggestionValue(v);
            return ""; // react-autosuggestのバグで、サジェスト中に上下キー押下するとundefinedが入ってくる 2022/03/10現在、react-autosuggestの最新でも直っていない
          }}
          onSuggestionsFetchRequested={this.onSuggestionsUpdateRequested.bind(
            this,
          )}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(
            this,
          )}
          shouldRenderSuggestions={this.shouldRenderSuggestions.bind(this)}
          inputProps={inputProps}
          onSuggestionSelected={this.onSuggestionSelected.bind(this)}
          renderSuggestion={this.renderSuggestion.bind(this)}
          focusFirstSuggestion={this.props.focusFirstSuggestion}
        />
        {value ? (
          <span
            type="button"
            className="react-autosuggest-clear-btn"
            tabIndex="0"
          >
            <i
              className="fa fa-times-circle"
              onClick={this.clearValue.bind(this)}
            ></i>
          </span>
        ) : (
          <span className="react-autosuggest-arrow-btn">
            <i className="fas fa-angle-down" />
          </span>
        )}
      </div>
    );
  }
}

AutoSuggestWrapper.theme = {
  container: "react-autosuggest-container",
  containerOpen: "react-autosuggest-container-open",
  input: "react-autosuggest-input",
  inputOpen: "react-autosuggest-input-open",
  inputFocused: "react-autosuggest-input-focused",
  suggestionsContainer: "react-autosuggest-suggestions-container",
  suggestionsContainerOpen: "react-autosuggest-suggestions-container-open",
  suggestionsList: "react-autosuggest-suggestions-list",
  suggestion: "react-autosuggest-suggestion",
  suggestionFirst: "react-autosuggest-suggestion-first",
  suggestionHighlighted: "react-autosuggest-suggestion-highlighted",
  sectionContainer: "react-autosuggest-section-container",
  sectionContainerFirst: "react-autosuggest-section-container-first",
  sectionTitle: "react-autosuggest-section-title",
};

AutoSuggestWrapper.defaultProps = {
  className: "autosuggest-default", // 'auto-suggest-default' or 'autosuggest-small'
  disabled: false,
};

AutoSuggestWrapper.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  inputProps: PropTypes.object,
  isImmutable: PropTypes.bool, // if true, the component does not set any value to the state.
  value: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  getSuggestionValue: PropTypes.func,
  shouldRenderSuggestions: PropTypes.func,
  onTextChanged: PropTypes.func,
  onSuggestionSelected: PropTypes.func,
  onSuggestionsUpdateRequested: PropTypes.func,
  onFocus: PropTypes.func,
  renderSuggestion: PropTypes.func,
  clearValue: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  focusFirstSuggestion: PropTypes.bool,
};
