import { has, pick, unset } from "lodash";
import WebStorage from "utilities/web_storage";

export interface GenericFieldSearchCondition {
  readonly id: string;
  readonly type: "code" | "name";
  readonly value: string;
}

/**
 * LocalStrage: 検索条件に保存する内容
 */
export interface StoredData {
  _version?: string;
  alert: {
    stampedWithinDueDateRule: boolean;
  };
  applicantName: string;
  approvalFlowName: string;
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  approvedApprover: null | object;
  approvedApproverName: string;
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  approver: null | object;
  approverName: string; // suggestを出すために使用
  disabledStatusForApprover: boolean;
  lastApprovedAtFrom: string;
  lastApprovedAtTo: string;
  matchedOriginalReceipt: boolean;
  notMatchedOriginalReceipt: boolean;
  projectId: string;
  projectName: string;
  reportTypes: {
    noTemporaryPayment: boolean;
    report: boolean;
    temporaryPayment: boolean;
  };
  requestType: {
    [key: string]: {
      name: string;
      checked: boolean;
    };
  };
  scope: string | null;
  searchedProjectId: string;
  searchedProjectName: string;
  sequenceNum: string;
  status: {
    forApprover: string;
    forReport: {
      applying: boolean;
      approved: boolean;
      closed: boolean;
      created: boolean;
      paid: boolean;
      pendingTemporaryPayment: boolean;
      pendingTemporaryPaymentRefund: boolean;
      recalled: boolean;
      rejected: boolean;
      settled: boolean;
      unsettled: boolean;
    };
  };
  submittedAtFrom: string;
  submittedAtTo: string;
  title: string;
  transactedAtFrom: string;
  transactedAtTo: string;
  /** TOKIUM インボイス用の検索条件 */
  invoice: {
    dueFrom: string;
    dueTo: string;
    scheduleFrom: string;
    scheduleTo: string;
    reportLabelIds: string[];
    exclusionLabel: boolean;
    registratedNumber: string;
    // 適格請求書の取り扱い
    asInvoiceChecks: boolean[];
  };
  genericFields: GenericFieldSearchCondition[];
}

/**
 * 申請の検索条件を保存するために使用する、LocalStorageのモデル
 */
export class SearchParamsStorage {
  private storage: WebStorage;

  private _data: null | StoredData;

  /**
   * スキーマのバージョン。変更するたびに上げる。
   * ただし、バージョン比較が文字列比較なので、バージョンの各桁はそれぞれ0~9までの数字のみとする。（例、OK = 1.0.9、NG = 1.0.11 ）
   *
   * @see SearchParamsStorage#migrateSchema 各バージョンの移行処理はこのクラス内に書く。
   */
  static readonly VERSION = "1.0.1";

  constructor() {
    this.storage = new WebStorage();
    this._data = null;

    this.migrate();
  }

  public get data(): StoredData {
    return this._data || this.storage.getItem(this.key) || this.defaultData;
  }

  public set data(params: StoredData) {
    this._data = pick(params, Object.keys(this.defaultData)) as StoredData;
    this.storage.setItem(this.key, {
      ...this._data,
      _version: SearchParamsStorage.VERSION,
    });
  }

  public get defaultData(): Omit<StoredData, "_version"> {
    return {
      alert: {
        stampedWithinDueDateRule: false,
      },
      applicantName: "",
      approvalFlowName: "",
      approvedApprover: null,
      approvedApproverName: "",
      approver: null,
      approverName: "", // suggestを出すために使用
      disabledStatusForApprover: false,
      lastApprovedAtFrom: "",
      lastApprovedAtTo: "",
      matchedOriginalReceipt: false,
      notMatchedOriginalReceipt: false,
      projectId: "",
      projectName: "",
      reportTypes: {
        noTemporaryPayment: true,
        report: true,
        temporaryPayment: true,
      }, // 経費精算、精算申請のフィルタリングに使う
      requestType: {},
      scope: "list",
      searchedProjectId: "",
      searchedProjectName: "",
      sequenceNum: "",
      status: {
        forApprover: "",
        forReport: {
          applying: false,
          approved: false,
          closed: false,
          created: false,
          paid: false,
          pendingTemporaryPayment: false,
          pendingTemporaryPaymentRefund: false,
          recalled: false,
          rejected: false,
          settled: false,
          unsettled: false,
        },
      },
      submittedAtFrom: "",
      submittedAtTo: "",
      title: "",
      transactedAtFrom: "",
      transactedAtTo: "",
      invoice: {
        dueFrom: "",
        dueTo: "",
        scheduleFrom: "",
        scheduleTo: "",
        reportLabelIds: [],
        exclusionLabel: false,
        registratedNumber: "",
        asInvoiceChecks: [],
      },
      genericFields: [],
    };
  }

  public clear(): void {
    this.data = { ...this.defaultData };
  }

  private get key(): string[] {
    return ["requests", "searchCondition"];
  }

  private migrate(): void {
    this.data = this.migrateSchema(this.data);
  }

  private migrateSchema(params: StoredData): StoredData {
    if (params) {
      const oldValue = params;
      let newValue = oldValue;

      // remove cache params reapplying for report status
      if (has(newValue, "status.forReport.reapplying")) {
        unset(newValue, "status.forReport.reapplying");
      }

      // eslint-disable-next-line dot-notation
      const oldVersion = oldValue["_version"] ?? SearchParamsStorage.VERSION;

      /** v1.0.0 -> v1.0.1の移行 (invoice.scheduleFrom, invoice.scheduleToの初期化) */
      if (oldVersion < "1.0.1") {
        newValue = {
          ...newValue,
          _version: "1.0.1",
          invoice: {
            ...newValue.invoice,
            scheduleFrom: "",
            scheduleTo: "",
          },
        };
      }

      return newValue;
    }

    return this.defaultData;
  }
}

export default new SearchParamsStorage();
