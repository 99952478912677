import React from "react";
import { Button } from "../Button";
import { ButtonProps } from "../Button/Button.types";

interface Props extends ButtonProps {
  readonly bsRole: "toggle";
}

export const BSDropdownButton = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    return (
      <Button {...props}>
        {props.children}
        <i
          ref={ref}
          className="fas fa-angle-down"
          style={{ marginLeft: "6px" }}
        />
      </Button>
    );
  },
);
