import { uniqueId } from "lodash";
import { useMemo, useState } from "react";

interface UseReturn {
  isOpen: boolean;
  isHover: boolean;
  targetId: string;
  onClickHeader: () => void;
  onClickResetButton: (e: React.MouseEvent) => void;
  onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
  onMouseEnterButton: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave: React.MouseEventHandler<HTMLDivElement>;
}

/**
 * ロジック: アコーディオン
 */
export const useHooks = (onClickReset: () => void): UseReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  /**
   * アコーディオンのターゲットID
   */
  const targetId = useMemo((): string => uniqueId("accordion"), []);

  /**
   * アコーディオンをクリックした
   */
  const onClickHeader = (): void => {
    setIsOpen((s) => !s);
  };

  /**
   * リセットをクリックした
   */
  const onClickResetButton = (e: React.MouseEvent): void => {
    e.stopPropagation();
    onClickReset();
  };

  /**
   * ホバーした
   */
  const onMouseEnter = (): void => {
    setIsHover(true);
  };

  /**
   * ボタンにホバーした
   */
  const onMouseEnterButton = (): void => {
    setIsHover(true);
  };

  /**
   * ホバーが外れた
   */
  const onMouseLeave = (): void => {
    setIsHover(false);
  };

  return {
    isOpen,
    isHover,
    targetId,
    onClickHeader,
    onClickResetButton,
    onMouseEnter,
    onMouseEnterButton,
    onMouseLeave,
  };
};
